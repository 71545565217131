import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'linaria'
import Img from 'gatsby-image'

import Layout from 'components/Layout'
import FooterCTA from 'components/Footer/CTA'

import Button from 'components/Button'
import Section from 'components/Section'
import SectionContent from 'components/Section/Content'
import NonStretchedImage from '../../components/NonStretchedImage'
import { HeroHeading, Subheading, Lead } from '../../components/TextElements'

import Hero from '../../elements/Hero'
import TechnologiesList from '../../elements/TechnologiesList'
import SmallTestimonials from '../../elements/Testimonials/Small'
import IconBox from '../../elements/IconBox'
import Products from '../../elements/Products'
import FAQ from '../../elements/FAQ'

import rhythm from '../../utils/rhythm'

const ogImage = '/img/og/services-pwa-og.jpg'

const heroContent = (
  <>
    <Subheading>Progressive Web App</Subheading>
    <HeroHeading>A fast app-like experience in a browser</HeroHeading>
    <Lead>
      Developing your business with Progressive Web App saves you money and time
      you’d spend on building native apps for multiple platforms. Going JAMstack
      way will give you low hosting costs and incredibly good performance, so
      you won’t lose any users.
    </Lead>
    <Button to="/services/progressive-web-app/#jamstack">View more</Button>
  </>
)

export default ({ location, data }) => (
  <Layout
    location={location}
    title="Progressive Web Apps for your business needs."
    description="Prepare your business for the future with a fast app-like experience in a browser thanks to Progressive Web Apps."
    image={ogImage}
    preLinksComponent={<FooterCTA />}
  >
    <Hero
      content={heroContent}
      image={data.hero}
      className={css`
        ${{ paddingBottom: rhythm(2) }}
      `}
    />
    <Section
      variant="lightGray"
      id="jamstack"
      footer={
        <Button to={'/blog/jamstack/'} state={{ fromBlog: false }}>
          Read more
        </Button>
      }
      footerClassName={css`
        ${{ marginTop: rhythm(1) }};
      `}
    >
      <SectionContent
        wide
        className={css`
          ${{ marginTop: 0, alignItems: 'center' }};
        `}
      >
        <div>
          <h2>What is JAMstack</h2>
          <Lead>
            JAMstack means an alternative approach for building websites and web
            applications. The JAM here stands for JavaScript, APIs, and
            Markdown. For your progressive web application, it means lower
            costs, scalability, and incredibly good performance.
          </Lead>
          <Lead>
            Building our apps we use battle-tested technologies like GatsbyJS,
            NextJS, Contentful, AWS, Netlify, and others.
          </Lead>
        </div>
        <div>
          <NonStretchedImage
            fluid={data.whatIllu.childImageSharp.fluid}
            style={{ marginRight: 0 }}
          />
        </div>
      </SectionContent>
    </Section>
    <Section>
      <SectionContent
        wide
        columnGap="2"
        rowGap="1"
        className={css`
          ${{
            marginTop: 0,
            gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))'
          }};
        `}
      >
        <header>
          <Subheading>Sites with Benefits</Subheading>
          <h2>How JAMstack supports your PWA</h2>
        </header>
        <IconBox>
          <Img
            fixed={data.benefitMobile.childImageSharp.fixed}
            alt="Good mobile experience"
          />
          <h3>Good mobile experience</h3>
          <p>
            Give users all mobile app features in the browser, without pushing
            them to download your app. Also offline.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.benefitOneDevelopment.childImageSharp.fixed}
            alt="One development"
          />
          <h3>One development</h3>
          <p>
            Don’t bother building many platforms for different OSes and devices.
            Save money instead - PWA can be both a website and mobile app.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.benefitPerformance.childImageSharp.fixed}
            alt="Great performance"
          />
          <h3>Great performance</h3>
          <p>
            As it caches data, exploring the app is incredibly fast,
            guaranteeing delightful mobile experiences on all devices.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.benefitScalability.childImageSharp.fixed}
            alt="Scalability"
          />
          <h3>Scalability</h3>
          <p>
            PWAs are great for moving web apps to mobile devices. Serverless
            architecture scales automatically as your user base or usage
            increases.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.benefitLowCost.childImageSharp.fixed}
            alt="Low-cost"
          />
          <h3>Low-cost</h3>
          <p>
            With JAMstack, your PWA can be hosted like any static site. Nothing
            minimizes hosting expenses better than serving static assets from
            the CNDs.
          </p>
        </IconBox>
      </SectionContent>
    </Section>
    <Section
      variant="lightGray"
      header={
        <>
          <h2>Testimonials</h2>
        </>
      }
    >
      <SectionContent
        rowGap="1"
        columnGap={`calc(4vw + ${rhythm(3 / 2)})`}
        maxWidth="44"
        className={css`
          ${{
            gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))'
          }};
        `}
      >
        <SmallTestimonials
          elements={[
            {
              content: `Our loading times greatly improved, from five or six seconds to 400 milliseconds.`,
              author: `Alen Todorov`,
              role: `Marketing and Growth Manager, SEOmonitor`,
              photo: `/img/alen-todorov.jpg`
            },
            {
              content: `The successful switch to JAMstack has increased website and data security while also improving site performance and speed.`,
              author: `Yan Schuppli`,
              role: `Founder, Codevelop Technologies GmbH`,
              photo: `/img/yan-schuppli.jpg`
            },
            {
              content: `Bejamas’ responsiveness and ability to turn constructive feedback into implementable, on-time deliverables are hallmarks of their work. Their can-do attitude made them a valuable partner.`,
              author: `Paolo Stolfo`,
              role: `Art Director, KREAM`,
              photo: `/img/paolo-stolfo.jpg`
            }
          ]}
        />
      </SectionContent>
    </Section>
    <Section
      header={
        <>
          <Subheading>Technologies we use</Subheading>
          <h2>Beautifying the web</h2>
        </>
      }
    >
      <TechnologiesList />
    </Section>
    <Section
      variant="lightGray"
      header={
        <>
          <Subheading>Workflow</Subheading>
          <h2>Get your project a pro service</h2>
        </>
      }
    >
      <SectionContent
        columnGap={`calc(6vw + ${rhythm(2)})`}
        className={css`
          ${{
            gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))'
          }};
        `}
      >
        <IconBox withNumber>
          <Img
            fixed={data.icon1.childImageSharp.fixed}
            alt="Define project goals"
          />
          <h3>Define project goals</h3>
          <p>
            We’ll discuss the main objectives of your PWA to keep them in mind
            during the whole project.
          </p>
        </IconBox>
        <IconBox withNumber>
          <Img
            fixed={data.icon2.childImageSharp.fixed}
            alt="Suitable toolset"
          />
          <h3>Suitable toolset</h3>
          <p>
            Thanks to our know-how we’ll present you a couple of technologies,
            that will let your PWA meet your goals best.
          </p>
        </IconBox>
        <IconBox withNumber>
          <Img
            fixed={data.icon3.childImageSharp.fixed}
            alt="Transparent work environment"
          />
          <h3>Transparent work environment</h3>
          <p>
            Slack workspace, Jira project, Github repository, you’ll get access
            to all of these to always be in the know how your PWA project is
            going.
          </p>
        </IconBox>
        <IconBox withNumber>
          <Img
            fixed={data.icon5.childImageSharp.fixed}
            alt="Afterproject relationship"
          />
          <h3>Afterproject relationship</h3>
          <p>
            As with all our projects, we’ll be glad to stay in touch with you
            after the PWA is built, handling all your feature requests and
            maintenance, so you can focus on growing your business.
          </p>
        </IconBox>
      </SectionContent>
    </Section>
    <Section
      header={
        <>
          <Subheading>Looking for other services?</Subheading>
          <h2>JAMstack Marketing Websites</h2>
          <Lead>
            Raise your website’s visibility and get more customers for your
            business with SEO-friendly websites.
          </Lead>
        </>
      }
      footer={<Button to="/services/jamstack-website/">Read more</Button>}
      footerClassName={css`
        ${{ marginTop: rhythm(2) }};
      `}
    />
  </Layout>
)

export const query = graphql`
  query ServicesPwaPageQuery {
    hero: file(
      relativePath: { regex: "/img/services/pwa/" }
      name: { eq: "PWA_hero" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, traceSVG: { color: "#ff5d3e" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    whatIllu: file(
      relativePath: { regex: "/img/services/jamstack/" }
      name: { eq: "What_is_JAM" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, traceSVG: { color: "#ff5d3e" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    benefitMobile: file(
      relativePath: { regex: "/img/services/pwa/" }
      name: { eq: "PWA_good_mobile" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    benefitOneDevelopment: file(
      relativePath: { regex: "/img/services/pwa/" }
      name: { eq: "PWA_one_development" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    benefitPerformance: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "JAM_performance" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    benefitScalability: file(
      relativePath: { regex: "/img/services/pwa/" }
      name: { eq: "PWA_scalability" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    benefitLowCost: file(
      relativePath: { regex: "/img/services/pwa/" }
      name: { eq: "PWA_low_cost" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon1: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_01" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon2: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_02" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon3: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_03" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon4: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_04" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon5: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_05" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`
